<template>
  <v-container class="app-page">
    <v-row>
      <v-col>
        <v-row v-if="loading">
          <v-col class="pa-1" v-for="i in 5" :key="'skeleton-col' + '-' + i" cols="12">
            <v-card class="pa-5">
              <v-skeleton-loader
                :ref="'skeleton-' + i"
                :boilerplate="false"
                type="paragraph"
                :tile="false"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            <v-card @click="navigate('adelantos')" class="list-item">
              <img class="card-icon" src="@/assets/solicitar/adelantos.svg" />
              <div class="list-content">
                <div class="title">Adelantos</div>
              </div>
            </v-card>

            <v-card @click="navigate('licencias')" class="list-item">
              <img class="card-icon" src="@/assets/solicitar/licencias.svg" />
              <div class="list-content">
                <div class="title">Licencias</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-center">
        <p class="mensaje">
          Sistema de liquidación de sueldos
          <br />GNS Software
        </p>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogPermission" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Atención
        </v-card-title>

        <v-card-text>
          La empresa no autorizó su utilización
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey darken-1"
            text
            @click="dialogPermission = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { hasPermission } from '@/utils/permission';

export default {
  name: "Solicitar",
  data() {
    return {
      loading: false,
      dialogPermission: false
    };
  },

  created() {},

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Solicitar");
    this.$store.commit("setActiveNav", 0);
  },

  methods: {
    hasPermission,
    navigate(route) {
      const areas = {
        'adelantos': 3,
        'licencias': 8
      }
      if(this.hasPermission(areas[route])) {
        this.$router.push({
          name: route,
        })
      } else {
        this.dialogPermission = true
      }
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.mensaje {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113%;
  text-align: center;
  color: rgba(67, 67, 67, 0.4);
}
</style>